// --- Third Party Libraries ---
import React, { useState } from "react";
import { useTranslation } from 'react-i18next';
import { Typography, Grid, Card, CardHeader, CardContent, CardActions, Avatar } from "@mui/material";
// --- Internal Components ---
import marketingAvatar from "./../../assets/img/marketing-avatar.png";
import salesAvatar from "./../../assets/img/sales-avatar.png";
import supportAvatar from "./../../assets/img/support-avatar.png";
// --- CSS ---
import "./index.css";

// --- Header Component ---
const Problem = () => {
  // Step 1 - What I need to do on component load
  // State variables and hooks
  const [hoverStates, setHoverStates] = useState([false, false, false]);
  const { t } = useTranslation();

  // Step 2 - Event Handlers
  const handleMouseEnter = (index) => {
    setHoverStates((currentHoverStates) =>
      currentHoverStates.map((state, i) => i === index ? true : state)
    );
  };

  const handleMouseLeave = (index) => {
    setHoverStates((currentHoverStates) =>
      currentHoverStates.map((state, i) => i === index ? false : state)
    );
  };

  // Step 3 - Constants
  const newCardDetails = [
    { icon: <span className="problem-card-header-icon" role="img" aria-label="target">🎯</span>, title: t("landingHeader.firstProblemComponentCardTitle"), problem: t("landingHeader.firstProblemComponentCardProblem"), avatar: marketingAvatar, name: "Robert", proff: t("landingHeader.firstProblemComponentCardProff") },
    { icon: <span className="problem-card-header-icon" role="img" aria-label="target">🛍</span>, title: t("landingHeader.secondProblemComponentCardTitle"), problem: t("landingHeader.secondProblemComponentCardProblem"), avatar: salesAvatar, name: "Tom", proff: t("landingHeader.secondProblemComponentCardProff") },
    { icon: <span className="problem-card-header-icon" role="img" aria-label="target">🤝</span>, title: t("landingHeader.thirdProblemComponentCardTitle"), problem: t("landingHeader.thirdProblemComponentCardProblem"), avatar: supportAvatar, name: "Jane", proff: t("landingHeader.thirdProblemComponentCardProff") },
  ];

  const hoverCardContentDetails = [
    {
      icon: <span className="solution-card-header-icon" role="img" aria-label="target">🎯</span>,
      title: t("landingHeader.firstProblemComponentCardTitle"),
      list: (
        <ul className="solution-card-content-list">
          <li>{t("landingHeader.cardOneSolutionCardContentListOne")}</li>
          <li>{t("landingHeader.cardOneSolutionCardContentListTwo")}</li>
          <li>{t("landingHeader.cardOneSolutionCardContentListThree")}</li>
          <li>{t("landingHeader.cardOneSolutionCardContentListFour")}</li>
        </ul>
      ),
    },
    {
      icon: <span className="solution-card-header-icon" role="img" aria-label="target">🛍</span>,
      title: t("landingHeader.secondProblemComponentCardTitle"),
      list: (
        <ul className="solution-card-content-list">
          <li>{t("landingHeader.cardTwoSolutionCardContentListOne")}</li>
          <li>{t("landingHeader.cardTwoSolutionCardContentListTwo")}</li>
          <li>{t("landingHeader.cardTwoSolutionCardContentListThree")}</li>
          <li>{t("landingHeader.cardTwoSolutionCardContentListFour")}</li>
          <li>{t("landingHeader.cardTwoSolutionCardContentListFIve")}</li>
        </ul>
      ),
    },
    {
      icon: <span className="solution-card-header-icon" role="img" aria-label="target">🤝</span>,
      title: t("landingHeader.thirdProblemComponentCardTitle"),
      list: (
        <ul className="solution-card-content-list">
          <li>{t("landingHeader.cardThreeSolutionCardContentListOne")}</li>
          <li>{t("landingHeader.cardThreeSolutionCardContentListTwo")}</li>
          <li>{t("landingHeader.cardThreeSolutionCardContentListThree")}</li>
          <li>{t("landingHeader.cardThreeSolutionCardContentListFour")}</li>
        </ul>
      ),
    },
  ];

  // Step 4 - Render Problem Component
  return (
    <Grid id="problem-section" className="problem-section" container spacing={2}>
      <Typography className="problem-component-title gradient-text" variant="h2">
        <span className="black-text">{t("landingHeader.problemComponentTitleBlack")}</span><br /> {t("landingHeader.problemComponentTitleBlue")}
      </Typography>
      {newCardDetails.map((card, index) => (
        <Grid key={index} item xs={12} md={4}>
          <Card
            className="problem-card-wrapper"
            onMouseEnter={() => handleMouseEnter(index)}
            onMouseLeave={() => handleMouseLeave(index)}
          >
            {hoverStates[index] ? (
              <>
                <CardHeader
                  className="solution-card-header"
                  title={<Typography className="solution-card-header-title" variant="h5">{hoverCardContentDetails[index].title}</Typography>}
                  avatar={hoverCardContentDetails[index].icon}
                />
                <CardContent className="solution-card-content">
                  <Typography className="solution-card-content-title" variant="caption">{t("landingHeader.solutionCardContentTitle")}</Typography>
                  {hoverCardContentDetails[index].list}
                </CardContent>
              </>
            ) : (
              <>
                <CardHeader
                  className="problem-card-header"
                  title={<Typography className="problem-card-header-title" variant="h5">{card.title}</Typography>}
                  avatar={card.icon}
                />
                <CardContent className="problem-card-content">
                  <Typography className="problem-card-content-title" variant="caption">{t("landingHeader.problemCardContentTitle")}</Typography>
                  <Typography className="problem-card-content-subtitle" variant="body2">{card.problem}</Typography>
                </CardContent>
                <CardActions className="problem-card-actions">
                  <Avatar src={card.avatar} />
                  <div>
                    <Typography className="problem-card-actions-title" variant="body1">{card.name}</Typography>
                    <Typography className="problem-card-actions-subtitle" variant="caption">{card.proff}</Typography>
                  </div>
                </CardActions>
              </>
            )}
          </Card>
        </Grid>
      ))}
    </Grid>
  );
}

export default Problem;