// --- Third Party Libraries ---
import React from "react";
import { Box, Grid, Typography, Card, CardHeader, CardContent } from "@mui/material";
import { useTranslation } from 'react-i18next';
// --- Internal Components ---
import liveChat from "../../assets/img/liveChat.png"
// --- CSS ---
import "./index.css";

// --- ChatBots Component ---
const LiveChat = () => {
    const { t } = useTranslation();
    // Step 1 - What I need to do on component load
    // State variables and hooks
    const cardsData = [
        {
            icon: <span className="liveChat-header-icon" role="img" aria-label="woman-technologist">👩‍💻</span>,
            title: t("landingHeader.liveChatCardFirstTitle"),
            content: t("landingHeader.liveChatCardFirstContent"),
            backgroundColor: "rgba(35, 35, 38, 1)",
            color: "rgba(255, 255, 255, 1)"
        },
        {
            icon: <span className="liveChat-header-icon" role="img" aria-label="mobile-phone-with-arrow">📲</span>,
            title: t("landingHeader.liveChatCardSecondTitle"),
            content: t("landingHeader.liveChatCardSecondContent"),
            backgroundColor: "rgba(255, 255, 255, 1)"
        },
        {
            icon: <span className="liveChat-header-icon" role="img" aria-label="globe-showing-americas">🌎</span>,
            title: t("landingHeader.liveChatCardThirdTitle"),
            content: t("landingHeader.liveChatCardThirdContent"),
            backgroundColor: "rgba(236, 234, 255, 1)"
        },
        {
            icon: <span className="liveChat-header-icon" role="img" aria-label="handshake">🤝</span>,
            title: t("landingHeader.liveChatCardFourthTitle"),
            content: t("landingHeader.liveChatCardFourthContent"),
            backgroundColor: "rgba(35, 35, 38, 1)",
            color: "rgba(255, 255, 255, 1)"
        },
    ];

    // Render LiveChat Component
    return (
        <>
            <Box className="liveChat-section">
                <Typography className="liveChat-title gradient-text" variant="h2">
                    <span className="black-text">{t("landingHeader.liveChatTitleFirstWord")} </span>{t("landingHeader.liveChatTitleSecondtWord")}
                    <span className="black-text"> {t("landingHeader.liveChatTitleThirdtWord")}</span>
                </Typography>
                <Typography className="liveChat-subtitle" variant="subtitle1">
                    {t("landingHeader.liveChatSubtitle")}
                </Typography>
                <Grid className="liveChat-content" container spacing={2}>
                    <Grid item xs={12} md={6} lg={6} order={{ xs: 2, md: 1 }}>
                        <img src={liveChat} alt="LiveChat" className="liveChat-image" />
                    </Grid>
                    <Grid item xs={12} md={6} lg={6} order={{ xs: 1, md: 2 }} className="liveChat-cards-container">
                        <Grid container spacing={2}>
                            {cardsData.map((card, index) => (
                                <Grid item md={6} lg={6} key={index}>
                                    <Card className="liveChat-card" style={{ backgroundColor: card.backgroundColor }}>
                                        <CardHeader
                                            className="liveChat-card-header"
                                            avatar={card.icon}
                                            title={<Typography className="liveChat-header-title" style={{ color: card.color }} variant="h5">{card.title}</Typography>}
                                        />
                                        <CardContent>
                                            <Typography className="liveChat-card-content" style={{ color: card.color }} variant="caption">{card.content}</Typography>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </>
    );
}

export default LiveChat;