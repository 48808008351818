import React, { useEffect } from "react";
import { Box, Grid, Typography, Card, CardMedia, CardContent } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import cardHeader1 from "../../assets/img/picture-article_1.png";
import cardHeader2 from "../../assets/img/picture-article_2.png";
import cardHeader3 from "../../assets/img/picture-article_3.png";
import Header from "./Header";
import Footer from "./Footer";
import "./index.css";
export default function BlogPage() {

    const images = { cardHeader1, cardHeader2, cardHeader3 };
    const navigate = useNavigate();
    const { t } = useTranslation();
    const handleCardClick = (id) => {
        navigate(`/blog/${id}`);
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <Header />
            <Box className="blog-page-container">
                <Typography variant="h4" className="blog-page-title">{t("blog.blogPageTitle")}</Typography>
                <Grid container spacing={3} >
                    {Object.entries(t('blog.articles', { returnObjects: true })).map(([id, article]) => (
                        <Grid item xs={12} sm={6} md={6} lg={4} key={id}>
                            <Card className="blog-card" onClick={() => handleCardClick(id)}>
                                <CardMedia
                                    className="blog-img"
                                    component="img"
                                    src={images[article.cardMedia]}
                                    alt={article.title}
                                />
                                <CardContent className="blog-content">
                                    <Typography className="blog-title" variant="caption">{article.title}</Typography>
                                    <Typography className="blog-subtitle" variant="body2">{article.subtitle}</Typography>
                                    <Typography className="blog-date" variant="body2">{article.date}</Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </Box>
            <Footer />
        </>
    )
}