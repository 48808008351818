import React, {useRef} from "react";
import Header from "./Header.jsx";
import Main from "./Main.jsx";
import Problem from "./Problem.jsx";
import ChatBots from "./ChatBots.jsx";
import LiveChat from "./LiveChat.jsx";
import Companion from "./Companion.jsx";
import KnowledgeCenter from "./KnowledgeCenter.jsx";
import Rewards from "./Rewards.jsx";
import BoostClientChats from "./BoostClientChats.jsx";
import Blog from "./Blog.jsx";
import Footer from "./Footer.jsx";

export default function LandingPage() {
  const copilotRef = useRef(null)
  const scrollToCopilot = () => copilotRef.current.scrollIntoView({behavior: "smooth", block: "start"}) 
  return (
    <>
      <Header />
      <Main scrollToCopilot={scrollToCopilot}/>
      <Problem />
      <Rewards />
      <ChatBots />
      <LiveChat />
      <section ref={copilotRef}>
        <Companion />
      </section> 
      <KnowledgeCenter />
      <BoostClientChats />
      <Blog />
      <Footer />
    </>
  );
}
