import React, {useState, useEffect} from "react";
import { useTranslation } from 'react-i18next';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Unstable_NumberInput as BaseNumberInput } from '@mui/base/Unstable_NumberInput';
import { styled } from '@mui/system';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';

const NumberInput = React.forwardRef(function CustomNumberInput(props, ref) {
    return (
      <BaseNumberInput
        slots={{
          root: StyledInputRoot,
          input: StyledInput,
          incrementButton: StyledButton,
          decrementButton: StyledButton,
        }}
        slotProps={{
          incrementButton: {
            children: <AddIcon fontSize="small" />,
            className: 'increment',
          },
          decrementButton: {
            children: <RemoveIcon fontSize="small" />,
          },
        }}
        {...props}
        ref={ref}
      />
    );
  });
  const blue = {
    100: '#daecff',
    200: '#b6daff',
    300: '#66b2ff',
    400: '#3399ff',
    500: '#007fff',
    600: '#0072e5',
    700: '#0059B2',
    800: '#004c99',
  };
  
  const grey = {
    50: '#F3F6F9',
    100: '#E5EAF2',
    200: '#DAE2ED',
    300: '#C7D0DD',
    400: '#B0B8C4',
    500: '#9DA8B7',
    600: '#6B7A90',
    700: '#434D5B',
    800: '#303740',
    900: '#1C2025',
  };
  
  const StyledInputRoot = styled('div')(
    ({ theme }) => `
    font-family: 'IBM Plex Sans', sans-serif;
    font-weight: 400;
    color: ${theme.palette.mode === 'dark' ? grey[300] : grey[500]};
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
  `,
  );
  
  const StyledInput = styled('input')(
    ({ theme }) => `
    font-size: 0.875rem;
    font-family: inherit;
    font-weight: 400;
    line-height: 1.375;
    color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
    background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
    border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
    box-shadow: 0px 2px 4px ${
      theme.palette.mode === 'dark' ? 'rgba(0,0,0, 0.5)' : 'rgba(0,0,0, 0.05)'
    };
    border-radius: 8px;
    margin: 0 8px;
    padding: 10px 12px;
    outline: 0;
    min-width: 0;
    width: 4rem;
    text-align: center;
  
    &:hover {
      border-color: ${blue[400]};
    }
  
    &:focus {
      border-color: ${blue[400]};
      box-shadow: 0 0 0 3px ${theme.palette.mode === 'dark' ? blue[700] : blue[200]};
    }
  
    &:focus-visible {
      outline: 0;
    }
  `,
  );
  
  const StyledButton = styled('button')(
    ({ theme }) => `
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 0.875rem;
    box-sizing: border-box;
    line-height: 1.5;
    border: 1px solid;
    border-radius: 999px;
    border-color: ${theme.palette.mode === 'dark' ? grey[800] : grey[200]};
    background: ${theme.palette.mode === 'dark' ? grey[900] : grey[50]};
    color: ${theme.palette.mode === 'dark' ? grey[200] : grey[900]};
    width: 32px;
    height: 32px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 120ms;
  
    &:hover {
      cursor: pointer;
      background: ${theme.palette.mode === 'dark' ? blue[700] : blue[500]};
      border-color: ${theme.palette.mode === 'dark' ? blue[500] : blue[400]};
      color: ${grey[50]};
    }
  
    &:focus-visible {
      outline: 0;
    }
  
    &.increment {
      order: 1;
    }
  `,
  );
function Addon({record, source, isProcessing, agents, isLogoPurchased, handleUserActivity}) {
    const { t } = useTranslation();
    const {name, description, price, metadata} = record;
    const [value, setValue] = useState(agents || 0);
    const isPoweredByAddon = name.toLowerCase().indexOf("intelswift") !== -1;
    const isAgentAddon = name.toLowerCase().indexOf("agent") !== -1;
    const isPricingPage = source === "pricing";
    const [buttonText, setButtonText] = useState("Get add-on")
    let disabled = false;
    
    if(isAgentAddon){
      if(value === agents){
        disabled = true;
      }
    }
    let min = 100, max = 10000, step = 100;
    if(isAgentAddon){
        min = 0;
        max = 100;
        step = 1;
    }
    const getTotal = () => {
        if(isAgentAddon){
            return price * value;
        }else{
            return (price * value) / 100;
        }
    }
    const handleCancel = () => {
      handleUserActivity(null, "cancel-logo")
    }
    const handlePurchase = () => {
      let count = 0;
      if(isPoweredByAddon){
        count = 1;
      }else if(isAgentAddon){
        count = value;
      }else{
        count = value / 100;
      }
      const {priceId, stripePlanId} = record;

      handleUserActivity({
        isAgent: isAgentAddon,
        priceId: priceId,
        productId: stripePlanId,
        count,
      }, "addon")
      //TODO: This value needs to be changed after confirm
      setValue(0)
    }
    useEffect(()=>{
      if(isAgentAddon && agents > 0){
        setValue(agents)
      }
    }, [agents])
    useEffect(()=>{
      if(isAgentAddon){
        if(value < agents){
          setButtonText(`Remove ${agents - value} agents`)
        }else{
          setButtonText(`Add ${value - agents} agents`)
        }
        
      }
    }, [value])
    useEffect(()=>{
      if(isPoweredByAddon){
        if(isLogoPurchased){
          setButtonText("Cancel add-on")
        }else{
          setButtonText("Get add-on")
        }
      }
    }, [isLogoPurchased])
    return (
        <Card className="add-on-card">
            <CardContent>
                <Typography  variant="h5" color="text.secondary" gutterBottom>
                    {name.replace("(add-on)", "")}
                </Typography>
                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                    {description}
                </Typography>
                {!isPoweredByAddon && (
                    <NumberInput 
                        aria-label="Quantity Input" 
                        min={min} 
                        max={max}
                        step={step}
                        value={value}
                        onChange={(event, val) => setValue(val)}
                    />
                )}
                {!isPoweredByAddon && (
                    <Typography variant="h6" color="text.secondary">
                        Total: ${getTotal()} {metadata?.monthly === 'true' ? '/month' : '' }
                    </Typography>
                )}
            </CardContent>
            <CardActions className="mt-auto">
                {!isPricingPage && (
                    <Button
                        className={isPoweredByAddon && isLogoPurchased ? "add-on-button-cancel" : "add-on-button"}
                        variant="contained"
                        ripple={false}
                        disabled={(value === 0 & !isPoweredByAddon) || isProcessing || disabled}
                        fullWidth={true}
                        onClick={isPoweredByAddon && isLogoPurchased ? handleCancel : handlePurchase}
                    >{buttonText}</Button>)
                }
            </CardActions>
        </Card>
    );
}

export default Addon;
