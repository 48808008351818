// --- Third Party Libraries ---
import React, { useState, useEffect } from "react";
import { Box, Button, IconButton, Typography, Stack, Grid, Divider, Card, CardHeader, CardContent, CardActions } from "@mui/material";
import { BsStars } from "react-icons/bs";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { AiFillGoogleCircle } from "react-icons/ai";
import { useTranslation } from 'react-i18next';
// --- Internal Components ---
import chatGptBlackLogo from "../../assets/img/chatgpt-logo-black.png";
import icon1 from "../../assets/img/main-section-icon1.png";
import icon2 from "../../assets/img/main-section-icon2.png";
import icon3 from "../../assets/img/main-section-icon3.png";
import salesAvatar from "./../../assets/img/sales-avatar.png";
import marketingAvatar from "./../../assets/img/marketing-avatar.png";
import { generateQueryMsg, generateReplyMsg, renderButtons } from "./../../components/chat/index"
// --- CSS ---
import "./index.css";

// Render Carousel Cards
const CarouselCard = ({ title, problem, solution, isActive, style, onClick, handleBack, handleNext, idx, t }) => (
  <Card
    className={`main-section-card ${isActive ? "active" : ""}`}
    style={{ cursor: "pointer", ...style }}
    onClick={onClick}
  >
    <CardHeader className="main-section-card-header" title={<Typography className="main-section-card-title">{title}</Typography>} />
    <CardContent className="main-section-card-content">
      <Box className="main-section-problem">
        <div className="square-container-problem">
          <span role="img" aria-label="thinking-face">🤔</span>
        </div>
        <Typography className="main-section-problem-text" variant="body2"><span>{t("landingHeader.mainSectionProblemText")}</span> {problem}</Typography>
      </Box>
      <Divider orientation="horizontal" flexItem />
      <Box className="main-section-solution">
        <div className="square-container-solution">
          <span role="img" aria-label="check-mark-button">✅</span>
        </div>
        <Typography className="main-section-solution-text" variant="body2"><span>{t("landingHeader.mainSectionSolutionText")}</span> {solution}</Typography>
      </Box>
      {idx === 0 && (
        <section className="chat-msg-history">
          {generateQueryMsg("You", marketingAvatar, t("landingHeader.firstChatMsgHistoryQuery"))}
          {generateReplyMsg("AI Copilot", icon3, t("landingHeader.firstChatMsgHistoryReply"))}
          <section className="btn-main-section">
            {renderButtons(t("landingHeader.firstChatMsgHistoryReplyBtn"))}
          </section>
        </section>
      )}
      {idx === 1 && (
        <section className="chat-msg-history">
          {generateQueryMsg("Adam", salesAvatar, t("landingHeader.secondChatMsgHistoryQueryOne"))}
          {generateReplyMsg("Swift", icon3, t("landingHeader.secondChatMsgHistoryReplyOne"))}
          {generateQueryMsg("Adam", salesAvatar, t("landingHeader.secondChatMsgHistoryQueryTwo"))}
          {generateReplyMsg("Swift", icon3, t("landingHeader.secondChatMsgHistoryReplyTwo"))}
          <section className="racket">
            {renderButtons("Mon Apr 15th 2PM")}
            {renderButtons("Mon Apr 15th 3PM")}
            {renderButtons("Tue Apr 16th 10AM")}
          </section>
          {generateQueryMsg("Adam", salesAvatar, t("landingHeader.secondChatMsgHistoryReplyThree"))}
        </section>
      )}
      {idx === 2 && (
        <section className="chat-msg-history">
          {generateReplyMsg("Swift", icon3, t("landingHeader.thrirdChatMsgHistoryQueryOne"))}
          {generateQueryMsg("Adam", salesAvatar, t("landingHeader.thrirdChatMsgHistoryReplyOne"))}
          {generateReplyMsg("Swift", icon3, t("landingHeader.thrirdChatMsgHistoryQueryTwo"))}
          {generateQueryMsg("Adam", salesAvatar, t("landingHeader.thrirdChatMsgHistoryReplyTwo"))}
        </section>
      )}
    </CardContent>
    {isActive && (
      <>
        <IconButton onClick={handleBack} className="carousel-button carousel-button-left">
          <IoIosArrowBack />
        </IconButton>
        <IconButton onClick={handleNext} className="carousel-button carousel-button-right">
          <IoIosArrowForward />
        </IconButton>
      </>
    )}
  </Card>
);

// --- Main Component ---
const Main = ({ scrollToCopilot }) => {
  // Step 1 - What I need to do on component load
  // State variables and hooks
  const [subtitleIndex, setSubtitleIndex] = useState(0);
  const { t } = useTranslation();
  const subtitleTexts = [
    t("landingHeader.mainSectionContentSubTitleOne"),
    t("landingHeader.mainSectionContentSubTitleTwo"),
    t("landingHeader.mainSectionContentSubTitleThree"),
  ];
  const animatedSubtitle = subtitleTexts[subtitleIndex];
  const [activeStep, setActiveStep] = useState(1);
  const totalSteps = 3;

  // Step 2 - Event Handlers
  const handleNext = (event) => {
    event.stopPropagation();
    setActiveStep((prevStep) => (prevStep + 1) % totalSteps);
  };

  const handleBack = (event) => {
    event.stopPropagation();
    setActiveStep((prevStep) => (prevStep - 1 + totalSteps) % totalSteps);
  };

  // Step 3 - useEffects
  // Use useEffect to periodically change the subtitle text
  useEffect(() => {
    const intervalId = setInterval(() => {
      // Increment the subtitle index and loop back to the first text if needed
      setSubtitleIndex((prevIndex) =>
        prevIndex === subtitleTexts.length - 1 ? 0 : prevIndex + 1
      );
    }, 3000); // Change the text every 3 seconds (adjust as needed)

    // Clean up the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, []);

  const cardDetails = [
    { title: t("landingHeader.cardDetailsOneTitle"), problem: t("landingHeader.cardDetailsOneProblem"), solution: t("landingHeader.cardDetailsOneSolution") },
    { title: t("landingHeader.cardDetailsTwoTitle"), problem: t("landingHeader.cardDetailsTwoProblem"), solution: t("landingHeader.cardDetailsTwoSolution") },
    { title: t("landingHeader.cardDetailsThreeTitle"), problem: t("landingHeader.cardDetailsThreeProblem"), solution: t("landingHeader.cardDetailsThreeSolution") },
  ];

  // Step 4 - Render Main Component
  return (
    <Box className="main-section-wrapper">
      <Box className="main-section-header">
        <Typography className="main-section-header-text" variant="caption">
          <BsStars /> {t("landingHeader.bsStarsTitle")}
        </Typography>
      </Box>
      <Grid container spacing={2} className="main-section-container">
        <Grid className="main-section-left-content" item xs={12} md={6} lg={6}>
          <Typography className="main-section-left-content-title" variant="h3">
            {t("landingHeader.mainSectionContentTitle")}
          </Typography>
          <Typography className="main-section-left-content-subtitle" variant="h3">
            {animatedSubtitle.split(" ")[0]}
            <br />
            {animatedSubtitle.split(" ").slice(1).join(" ")}
          </Typography>
          <Stack className="main-section-buttons-list" spacing={2} direction="row">
            {/* <Button className="how-it-works-button" variant="contained">How it works</Button> */}
            <Button className="discover-button" onClick={scrollToCopilot}>{t("landingHeader.discoverAiCopilotBtn")}</Button>
          </Stack>
          <Stack className="main-section-integrations" spacing={2} direction="row">
            <Typography className="integrations-title" variant="caption">
              LLMs
            </Typography>
            <Divider orientation="vertical" flexItem />
            <Stack className="integrations-icons-stack" spacing={2} direction="row">
              <span>
                <AiFillGoogleCircle className="integrations-icon" />
                Gemini
              </span>
              <span>
                <img src={chatGptBlackLogo} alt="Chat GPT Logo Black" className="integrations-icon" />
                GPT
              </span>
            </Stack>
          </Stack>
        </Grid>
        <Grid className="main-section-right-content" item xs={12} md={6} lg={6}>
          {cardDetails.map((card, index) => {
            const isActive = index === activeStep;
            let cardStyle = {};
            if (index === activeStep - 1 || (activeStep === 0 && index === cardDetails.length - 1)) {
              cardStyle = { transform: "scale(0.9) translateX(-22%) rotate(-7deg)", transition: "transform 0.5s ease", opacity: 1.0 };
            } else if (index === activeStep + 1 || (activeStep === cardDetails.length - 1 && index === 0)) {
              cardStyle = { transform: "scale(0.9) rotate(7deg)", transition: "transform 0.5s ease", opacity: 1.0 };
            }
            return (
              <CarouselCard
                key={index}
                title={card.title}
                problem={card.problem}
                solution={card.solution}
                isActive={isActive}
                style={cardStyle}
                onClick={() => setActiveStep(index)}
                handleBack={handleBack}
                handleNext={handleNext}
                idx={index}
                t={t}
              />
            );
          })}
          <img src={icon1} alt="Messages" className="custom-square1" />
          <img src={icon2} alt="Circle" className="custom-square3" />
          <img src={icon3} alt="Stars" className="custom-square4" />
        </Grid>
      </Grid>
    </Box>
  );
}

export default Main;