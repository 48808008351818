// --- Third Party Libraries ---
import React from "react";
import { Box, Stack, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { AiOutlineCopyright } from "react-icons/ai";
import { useTranslation } from 'react-i18next';
// --- Internal Components ---
import logo from "../../assets/img/horizontal-logo-for-black-background.png";
// --- CSS ---
import "./index.css";

// --- Footer Component ---
const Footer = () => {
  const { t } = useTranslation();

  // Step 1 - What I need to do on component load
  // Event Handlers
  const scrollToProblem = () => {
    const problemSection = document.getElementById("problem-section");
    if (problemSection) {
      window.scrollTo({
        top: problemSection.offsetTop,
        behavior: "smooth",
      });
    }
  };

  // Render Footer Component
  return (
    <Box className="footer-section">
      <img className="footer-logo" src={logo} alt="Logo" />
      <Stack direction="row" spacing={2}>
        <div className="footer-links-container">
          <Link to="/" className="footer-links" onClick={scrollToProblem}>
            <Typography variant="body2">{t("landingHeader.solutionsBtn")}</Typography>
          </Link>
          <Link to="/pricing" className="footer-links">
            <Typography className="footer-links" variant="body2">{t("landingHeader.pricingBtn")}</Typography>
          </Link>
          <Link to="/about" className="footer-links">
            <Typography className="footer-links" variant="body2">{t("landingHeader.aboutUsBtn")}</Typography>
          </Link>
          <Link to="/blog" className="footer-links">
            <Typography className="footer-links" variant="body2">{t("landingHeader.blogBtn")}</Typography>
          </Link>
          <Link to="/privacy-policy" className="footer-links">
            <Typography className="footer-links" variant="body2">{t("landingHeader.privacyPolicyBtn")}</Typography>
          </Link>
          <Link to="/terms-and-conditions" className="footer-links">
            <Typography className="footer-links" variant="body2">{t("landingHeader.termsConditionsBtn")}</Typography>
          </Link>
        </div>
      </Stack>
      <Typography className="footer-rights" variant="caption"><AiOutlineCopyright />IntelSwift Inc. {t("landingHeader.footerRights")}</Typography>
    </Box>
  );
}

export default Footer;