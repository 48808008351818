// --- Third Party Libraries ---
import React from "react";
import { Box, Grid, Card, CardMedia } from "@mui/material";
// --- Internal Components ---
import knowledgeCenter_1 from "../../assets/img/knowledge-img_1.png";
import knowledgeCenter_2 from "../../assets/img/knowledge-img_2.png";
// --- CSS ---
import "./index.css";

// --- KnowledgeCenter Component ---
const KnowledgeCenter = () => {
    // Render KnowledgeCenter Component
    return (
        <Box className="knowledgeCenter-section">
        <Grid container spacing={2}>
            <Grid item xs={12} md={6} lg={6}>
                <Card className="knowledgeCenter-card">
                    <CardMedia
                        component="img"
                        alt="Knowledge Image 1"
                        height="100%"
                        image={knowledgeCenter_1}
                    />
                </Card>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
                <Card className="knowledgeCenter-card">
                    <CardMedia
                        component="img"
                        alt="Knowledge Image 2"
                        height="100%"
                        image={knowledgeCenter_2}
                    />
                </Card>
            </Grid>
        </Grid>
    </Box>
    );
}

export default KnowledgeCenter;