import React from "react";
import { Typography, Avatar, Stack, Card, CardContent, CardActions, Button } from "@mui/material";
import './index.css';
import { useTranslation } from 'react-i18next';

const generateQueryMsg = (name, avatar, msg) => {
    return (
        <Stack className="user-query-container" spacing={1}>
            <Stack direction="row" className="name-and-avatar-container" spacing={1}>
                <Typography variant="caption">
                    {name}
                </Typography>
                {avatar && (
                    <Avatar className="avatar-container">
                        <img className="avatar-icon" src={avatar} alt="User icon" />
                    </Avatar>
                )}
            </Stack>
            <div className="chatbots-query-card-container">
                <Typography variant="caption">
                    {msg}
                </Typography>
            </div>
        </Stack>
    )
}
const generateReplyMsg = (name, avatar, msg) => {
    return (
        <Stack className="agent-reply-container" spacing={1}>
            <Stack direction="row" className="name-and-avatar-container" spacing={1}>
                <Avatar className="avatar-container">
                    <img className="avatar-icon" src={avatar} alt="Agent icon" />
                </Avatar>
                <Typography variant="caption">
                    {name}
                </Typography>
            </Stack>
            <div className="chatbots-reply-card-container">
                <Typography variant="caption">
                    {msg}
                </Typography>
            </div>
        </Stack>
    )
}

const RenderCardReply = (image, title) => {
    const { t } = useTranslation();
    return (
        <>
            <Card className="chatbots-gallery-card">
                <CardContent>
                    <img src={image} />
                    <Typography variant="subtitle1" className="chat-card-title">{title}</Typography>
                </CardContent>
                <CardActions className="chatbots-actions">
                    <Stack className="chatbots-buttons-column" spacing={2} direction="column">
                        <Button className="chatbots-learnMore-button" variant="text" color="primary">
                            {t("landingHeader.chatbotsLearnMoreButton")}
                        </Button>
                    </Stack>
                </CardActions>
            </Card>
        </>
    )
}

const renderButtons = (text) => {
    return (
        <Card className="chatbots-reply-button">
            <Typography variant="subtitle2">{text}</Typography>
        </Card>
    )
}

export {
    generateQueryMsg,
    generateReplyMsg,
    RenderCardReply,
    renderButtons
}