// --- Third Party Libraries ---
import React from "react";
import { Grid, Stack } from "@mui/material";
// --- CSS ---
import "./index.css";

const Rewards = () => {
    return (
        <Grid className="rewards-section" item xs={6} md={12} lg={12}>
            <Stack>
                <a href="https://www.producthunt.com/posts/intelswift?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-intelswift" target="_blank">
                    <img src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=446028&theme=neutral" alt="Intelswift - Customer Service Ai with Human Decision-making | Product Hunt" />
                </a>
            </Stack>
            <Stack>
                <a href="https://www.producthunt.com/posts/intelswift?utm_source=badge-top-post-topic-badge&utm_medium=badge&utm_souce=badge-intelswift" target="_blank">
                    <img src="https://api.producthunt.com/widgets/embed-image/v1/top-post-topic-badge.svg?post_id=446028&theme=neutral&period=weekly&topic_id=268" alt="Intelswift - Customer Service Ai with Human Decision-making | Product Hunt" />
                </a>
            </Stack>
            <Stack>
                <a href="https://www.producthunt.com/posts/intelswift?utm_source=badge-top-post-topic-badge&utm_medium=badge&utm_souce=badge-intelswift" target="_blank">
                    <img src="https://api.producthunt.com/widgets/embed-image/v1/top-post-topic-badge.svg?post_id=446028&theme=neutral&period=monthly&topic_id=237" alt="Intelswift - Customer Service Ai with Human Decision-making | Product Hunt" />
                </a>
            </Stack>
        </Grid>
    );
}

export default Rewards;