// --- Third Party Libraries ---
import React, { useState, useEffect } from "react";
import { Box, Typography, Tabs, Tab, Divider } from "@mui/material";
import { useTranslation } from 'react-i18next';
// Import internal components and assets
import promtBasedAnalyticsImage from "../../assets/img/promt-based-analytics.png";
import promtBasedAnalyticsMobileImage from "../../assets/img/promt-based-analytics-mobile.png";
import salesForecastsImage from "../../assets/img/sales-forecasts.png";
import salesForecastsMobileImage from "../../assets/img/sales-forecasts-mobile.png";
import anomalyDetectionImage from "../../assets/img/anomaly-detection.png";
import anomalyDetectionMobileImage from "../../assets/img/anomaly-detection-mobile.png";
import connectYourDataImage from "../../assets/img/connect-your-data.png";
import connectYourDataMobileImage from "../../assets/img/connect-your-data.png";
// --- CSS ---
import "./index.css";

// --- Companion Component ---
const Companion = () => {
    // Step 1 - What I need to do on component load
    // State variables and hooks
    const [activeTab, setActiveTab] = useState(0);
    const [isSmallScreen, setIsSmallScreen] = useState(false);
    const { t } = useTranslation();

    // Step 2 - Event Handlers
    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue);
    };

    // Step 3 - Constants
    const tabData = [
        t("landingHeader.companionFirstTab"),
        t("landingHeader.companionSeondTab"),
        t("landingHeader.companionThirdTab"),
        t("landingHeader.companionFourthTab"),
    ];

    const tabContent = [
        {
            text: t("landingHeader.companionFirstTabContent"),
            image: promtBasedAnalyticsImage,
            mobileImage: promtBasedAnalyticsMobileImage
        },
        {
            text: t("landingHeader.companionSeondTabContent"),
            image: salesForecastsImage,
            mobileImage: salesForecastsMobileImage
        },
        {
            text: t("landingHeader.companionThirdTabContent"),
            image: anomalyDetectionImage,
            mobileImage: anomalyDetectionMobileImage
        },
        {
            text: t("landingHeader.companionFourthTabContent"),
            image: connectYourDataImage,
            mobileImage: connectYourDataMobileImage
        },
    ];

    const tabIndicatorStyle = {
        backgroundColor: "white",
    };

    // Check screen size on component mount and on window resize
    useEffect(() => {
        const handleResize = () => {
            setIsSmallScreen(window.innerWidth < 900);
        };

        handleResize(); // Check initial screen size
        window.addEventListener("resize", handleResize); // Listen for window resize events

        // Cleanup
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    // Step 4 - Render Companion Component
    return (
        <Box className="companion-section" id="copilot">
            <Typography className="companion-title" variant="h4">
                AI Copilot
            </Typography>
            {isSmallScreen ? (
                <div className="scrollable-container">
                    {tabData.map((label, index) => (
                        <div key={index} className="companion-tab-image-placeholder">
                            <Typography className="companion-tab-label-title" variant="h5">{label}</Typography>
                            <Typography className="companion-tab-content-text" variant="caption">{tabContent[index].text}</Typography>
                            <img className="companion-tab-image" src={tabContent[index].mobileImage} alt={label} />
                        </div>
                    ))}
                </div>
            ) : (
                <>
                    <Tabs
                        value={activeTab}
                        onChange={handleTabChange}
                        variant="fullWidth"
                        TabIndicatorProps={{
                            style: tabIndicatorStyle,
                        }}
                    >
                        {tabData.map((label, index) => (
                            <Tab
                                key={index}
                                label={
                                    <div className={`${activeTab === index ? "active-tab" : ""}`}>
                                        <Typography className="companion-tab-label-title" variant="h5">{label}</Typography>
                                    </div>
                                } />
                        ))}
                    </Tabs>
                    <Divider className="companion-tab-divider" />
                    <div className="companion-tab-content">
                        <Typography className="companion-tab-content-text" variant="caption">{tabContent[activeTab].text}</Typography>
                        <img className="companion-tab-image" src={tabContent[activeTab].image} alt={tabData[activeTab]} />
                    </div>
                </>
            )}
        </Box>
    );
}

export default Companion;