import React from "react";
import { useTranslation } from "react-i18next";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { BsFillInfoCircleFill } from "react-icons/bs";

function PlanFeatures({name}) {
    const { t } = useTranslation();
    const features = {
        "Free": [
            {
                title: "Conversations credit 100",
                info: "We estimate that 1 conversation can have on average 10 messages"
            },
            {
                title: "All Channels",
                info: "Telegram, Web-widget, Webhook"
            },
            {
                title: "1 AI assistant",
                info: "1 AI assistant can be assigned to multiple channels"
            },
            {
                title: "Assistant training 400K char",
                info: "Number of characters your assistant can be trained on"
            },
            {
                title: "Live Chat",
                info: "Mobile and Web"
            },
            {
                title: "Basic human-handoff",
                info: "AI Assistant will assign conversation to human agent if it won't be able to answer the question"
            },
            {
                title: "Advanced message compose",
                info: "Send data rich messages to your users in a form of gallery, or message and quick actions"
            },
            {
                title: "Lead qualification (4 segments)",
                info: "Segment is they way for business to classify users. Example: Sales qualified lead, Cross-sell qualified lead, Upsell qualified lead"
            },
            {
                title: "Basic reporting",
                info: "You will only have a single dashboard we created for you"
            },
            {
                title: "Calendly",
                info: "AI Assistant can recommend available time slots for your prospects to schedule demo"
            }
        ],
        "Pro": [
            {
                title: "Conversations credit 500 /month",
                info: "We estimate that 1 conversation can have on average 10 messages"
            },
            {
                title: "All Channels",
                info: "Telegram, Web-widget, Webhook"
            },
            {
                title: "5 AI assistant",
                info: "1 AI assistant can be assigned to multiple channels"
            },
            {
                title: "Assistant training 2 million char",
                info: "Number of characters your assistant can be trained on"
            },
            {
                title: "Live Chat",
                info: "Mobile and Web"
            },
            {
                title: "Advanced human-handoff (AI Copilot)",
                info: "AI assistant will assign the conversation and provide conversation summary, so you won't have to spend time reading entire conversation"
            },
            {
                title: "AI message compose (AI Copilot)",
                info: "Ask Copilot to generate reply based on the conversation history and in the language of your prospect"
            },
            {
                title: "Lead qualification (10 segments)",
                info: "Segment is they way for business to classify users. Example: Sales qualified lead, Cross-sell qualified lead, Upsell qualified lead"
            },
            {
                title: "Advanced reporting",
                info: "Unlimited custom dashboards"
            },
            {
                title: "Calendly",
                info: "AI Assistant can recommend available time slots for your prospects to schedule demo"
            },
            {
                title: "Shopify",
                info: "AI assistant is capable of recommending products from your shopify store based on search criteria"
            }
        ],
        "Business": [
            {
                title: "Conversations credit 1,500 /month",
                info: "We estimate that 1 conversation can have on average 10 messages"
            },
            {
                title: "All Channels",
                info: "Telegram, Web-widget, Webhook"
            },
            {
                title: "10 AI assistant",
                info: "1 AI assistant can be assigned to multiple channels"
            },
            {
                title: "Assistant training 5 million char",
                info: "Number of characters your assistant can be trained on"
            },
            {
                title: "Live Chat",
                info: "Mobile and Web"
            },
            {
                title: "Advanced human-handoff (AI Copilot)",
                info: "AI assistant will assign the conversation and provide conversation summary, so you won't have to spend time reading entire conversation"
            },
            {
                title: "AI message compose (AI Copilot)",
                info: "Ask Copilot to generate reply based on the conversation history and in the language of your prospect"
            },
            {
                title: "Lead qualification (Unlimited segments)",
                info: "Segment is they way for business to classify users. Example: Sales qualified lead, Cross-sell qualified lead, Upsell qualified lead"
            },
            {
                title: "Advanced reporting",
                info: "Unlimited custom dashboards"
            },
            {
                title: "Calendly",
                info: "AI Assistant can recommend available time slots for your prospects to schedule demo"
            },
            {
                title: "Shopify",
                info: "AI assistant is capable of recommending products from your shopify store based on search criteria"
            },
            {
                title: "Data anomaly",
                info: "Copilot will inform you once anomaly is discovered in your data"
            },
            {
                title: "Data forecasting",
                info: "Simply ask Copilot in prompt to forecast data based based on your criteria"
            },
            {
                title: "External data connection",
                info: "Connect your own business data and enjoy all functionality of Copilot"
            },
            {
                title: "User roles",
                info: "Assign custom roles and permissions to your agents"
            },
            {
                title: "Insights Copilot",
                info: "Simply ask Copilot in prompt to generate report for you. Example: Show me leads who are qualified for sales, or generate conversation summaries for my agents in the last 2 days"
            }
        ],
        "Enterprise": [
            {
                title: "Custom conversations credit",
                info: "We estimate that 1 conversation can have on average 10 messages"
            },
            {
                title: "All Channels",
                info: "Telegram, Web-widget, Webhook"
            },
            {
                title: "10 AI assistant",
                info: "1 AI assistant can be assigned to multiple channels"
            },
            {
                title: "Assistant training 5 million char",
                info: "Number of characters your assistant can be trained on"
            },
            {
                title: "Live Chat",
                info: "Mobile and Web"
            },
            {
                title: "Advanced human-handoff (AI Copilot)",
                info: "AI assistant will assign the conversation and provide conversation summary, so you won't have to spend time reading entire conversation"
            },
            {
                title: "AI message compose (AI Copilot)",
                info: "Ask Copilot to generate reply based on the conversation history and in the language of your prospect"
            },
            {
                title: "Lead qualification (Unlimited segments)",
                info: "Segment is they way for business to classify users. Example: Sales qualified lead, Cross-sell qualified lead, Upsell qualified lead"
            },
            {
                title: "Advanced reporting",
                info: "Unlimited custom dashboards"
            },
            {
                title: "Calendly",
                info: "AI Assistant can recommend available time slots for your prospects to schedule demo"
            },
            {
                title: "Shopify",
                info: "AI assistant is capable of recommending products from your shopify store based on search criteria"
            },
            {
                title: "Data anomaly",
                info: "Copilot will inform you once anomaly is discovered in your data"
            },
            {
                title: "Data forecasting",
                info: "Simply ask Copilot in prompt to forecast data based based on your criteria"
            },
            {
                title: "External data connection",
                info: "Connect your own business data and enjoy all functionality of Copilot"
            },
            {
                title: "User roles",
                info: "Assign custom roles and permissions to your agents"
            },
            {
                title: "Insights Copilot",
                info: "Simply ask Copilot in prompt to generate report for you. Example: Show me leads who are qualified for sales, or generate conversation summaries for my agents in the last 2 days"
            },
            {
                title: "API & Integrations",
                info: "Custom integrations with your own tools and resources"
            },
            {
                title: "Account manager",
                info: "A dedicated team member always on top of your account"
            }
        ]
    }
    return (
        <ul style={{ padding: 0, paddingTop: 2 }}>
            {features[name].map((feature, index) => (
                <li
                    key={index}
                    className="feature-row"
                >
                    <Typography >
                        {feature.title}
                    </Typography>
                    <Tooltip title={feature.info} placement="bottom-start">                                
                        <IconButton size="small">
                            <BsFillInfoCircleFill className="info-icon" />
                        </IconButton>
                    </Tooltip>
                </li>
            ))}
        </ul>
    );
}

export default PlanFeatures;
