import React from "react";
import { Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
    Card,
    CardContent,
    CardActions,
    Typography,
    Button,
    Box,
    Stack,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import PlanFeatures from "./features"
const MAIN_APP_URL = process.env.REACT_APP_PRODUCTION_DOMAIN_ATOM_UI;

export default function PlansCard({
    source = "plans",
    displayButtons = true,
    plans,
    planType,
    activePlan,
    handleUserActivity,
}) {
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();

    const filteredPlans = (() => {
        const allPlans = [...plans];
        console.log("PLANS", plans)
        const monthlyPlans = allPlans.filter((plan) => plan.name.indexOf("annual") === -1 || plan.name === "Enterprise");
        const annualPlans = allPlans.filter((plan) => plan.name.indexOf("annual") !== -1 || plan.name === "Free" || plan.name === "Enterprise");

        if (planType === "Monthly") {
            return monthlyPlans
        } else {
            return annualPlans
        }
    })
    const calcPlanName = name => name.replace("annual", "").trim();
    const calcPlanPrice = price => {
        if (planType === "Annual") {
            return Math.round(price / 12)
        }

        return price
    };
    const handleCancel = () => {
        handleUserActivity(null, "cancel-plan")
    }

    const handlePurchase = (product) => {
        const { priceId, stripePlanId } = product;

        handleUserActivity({
            priceId: priceId,
            productId: stripePlanId,
        }, "plan")
    }
    return (
        <Stack className="plans-container">
            <Grid container spacing={2} className="inline-block-grid-item"
            >
                {
                    filteredPlans()?.length &&
                    filteredPlans().map((plan, id) => (
                        <Grid
                            item
                            key={`${plan?.name}-${id}`}
                            xs={12}
                            sm={12}
                            md={6}
                            lg={3}
                            className="plans-pricing-card">
                            <Card
                                key={plan?.name}
                                variant="gradient"
                                className={`card-pricing card-pricing-${id}`}
                            >
                                <Box className="gradient-border-content">
                                    <Box>
                                        <Box className={`pricing-plan-name-container-${id}`}>
                                            <Typography variant="h5"
                                                className={`pricing-plan-name pricing-plan-name-${id}`}
                                            >
                                                {calcPlanName(plan.name)}
                                            </Typography>
                                            {plan.name !== "Enterprise" && (
                                                <Typography variant="subtitle1" className="pricing-plan-price">
                                                    {`$${calcPlanPrice(plan?.price)}`}
                                                    <span className="pricing-plan-month">per month</span>
                                                </Typography>
                                            )}
                                            {plan.name === "Enterprise" && (
                                                <Button
                                                    className="contact-us-btn"
                                                    href="mailto:support@intelswift.com"
                                                >
                                                    Contact Us
                                                </Button>
                                            )}
                                        </Box>
                                        <Box className="pricing-plan-subtitle-wrapper">
                                            <Typography variant="subtitle2" className="pricing-plan-subtitle">
                                                {plan?.name.includes("Free") && "Ideal for individuals evaluating the product"}
                                                {plan?.name.includes("Pro") && "Perfect for small & growing teams"}
                                                {plan?.name.includes("Business") && "Ideal for businesses scaling their support"}
                                            </Typography>
                                        </Box>
                                        <Box className="plan-feature-content">
                                            <CardContent className="card-content-plan">
                                                <PlanFeatures name={calcPlanName(plan.name)} />
                                            </CardContent>
                                        </Box>
                                    </Box>
                                    <Box>
                                        <>
                                            {source === "pricing" ? (
                                                plan.name !== "Enterprise" && (
                                                    <CardActions className="btn-get-started-footer" variant="contained">
                                                        <Button onClick={() => window.open(MAIN_APP_URL, '_self', 'rel=noopener noreferrer')} className="btn-get-started" color="primary" size="large">Get started</Button>
                                                    </CardActions>
                                                )
                                            ) : (
                                                <CardActions className="card-footer-plans" style={{ marginTop: "auto" }}>
                                                    {displayButtons && plan.name !== "Free" && (
                                                        <>
                                                            {plan.name === activePlan ? (
                                                                <Button
                                                                    className="btn-cancel-plan"
                                                                    variant="contained"
                                                                    onClick={handleCancel}
                                                                    data-testid="cancel-button"
                                                                >
                                                                    {t("plans.cancel")}
                                                                </Button>
                                                            ) : (
                                                                <Button
                                                                    className="btn-use-plan"
                                                                    variant="contained"
                                                                    ripple={false}
                                                                    fullWidth={true}
                                                                    onClick={() => {
                                                                        handlePurchase(plan)
                                                                    }}
                                                                >
                                                                    Upgrade
                                                                </Button>
                                                            )}
                                                        </>
                                                    )}
                                                </CardActions>
                                            )}
                                        </>
                                    </Box>
                                </Box>
                            </Card>
                        </Grid>
                    ))}
            </Grid>
        </Stack >
    )
}