import React, { useState } from "react";
import { Box, Typography, Grid, Button } from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Header from "../LandingPage/Header";
import Footer from "../LandingPage/Footer";
import Cards from "../../components/PlansContent/cards";
import Addon from "../../components/PlansContent/addon";
import "./index.css";

// Hardcoded plans and addons data
const hardcodedPlans = [
    {
        active: true,
        createdAt: "2024-03-20T23:35:28.884Z",
        description: "IntelSwift Free plan",
        isDeleted: false,
        metadata: { conversations_credit: "0" },
        name: "Free",
        price: 0,
        priceId: "price_1OkzxXCGsJZY9lajOvYGKSwg",
        stripePlanId: "prod_PaAIh270C36jfM",
        updatedAt: "2024-04-02T06:07:38.801Z",
        __v: 0,
        _id: "65fb72c095aaf7bae97fa050"
    },
    {
        active: true,
        createdAt: "2024-03-20T23:35:28.694Z",
        description: "IntelSwift Pro plan",
        isDeleted: false,
        metadata: {
            assistants_credit: "5",
            characters_credit: "2000000",
            conversations_credit: "500",
            segments_credit: "10",
        },
        name: "Pro",
        price: 49,
        priceId: "price_1OkzuzCGsJZY9laj73lNn9Ti",
        stripePlanId: "prod_PaAFuFBd3Pc6Xr",
        updatedAt: "2024-04-02T06:07:39.240Z",
        __v: 0,
        _id: "65fb72c095aaf7bae97fa04d"
    },
    {
        active: true,
        createdAt: "2024-03-20T23:35:28.499Z",
        description: "IntelSwift Business plan",
        isDeleted: false,
        metadata: {
            assistants_credit: "10",
            characters_credit: "5000000",
            conversations_credit: "500",
            segments_credit: "20",
        },
        name: "Business",
        price: 125,
        priceId: "price_1Okzw2CGsJZY9lajyJKPEqvy",
        stripePlanId: "prod_PaAGEUtaNOXiGY",
        updatedAt: "2024-04-02T06:07:39.020Z",
        __v: 0,
        _id: "65fb72c095aaf7bae97fa04a"
    },
    {
        active: true,
        createdAt: "2024-03-20T23:35:28.304Z",
        description: "IntelSwift Pro plan annual",
        isDeleted: false,
        metadata: {
            assistants_credit: "5",
            characters_credit: "2000000",
            conversations_credit: "500",
            segments_credit: "10",
        },
        name: "Pro annual",
        price: 470,
        priceId: "price_1Ol00tCGsJZY9lajedAuAhFL",
        stripePlanId: "prod_PaAL8M35TcmqQy",
        updatedAt: "2024-04-02T06:07:38.364Z",
        __v: 0,
        _id: "65fb72c095aaf7bae97fa047"
    },
    {
        active: true,
        createdAt: "2024-03-20T23:35:28.112Z",
        description: "IntelSwift Business plan (annual)",
        isDeleted: false,
        metadata: {
            assistants_credit: "10",
            characters_credit: "5000000",
            conversations_credit: "500",
            segments_credit: "20",
        },
        name: "Business annual",
        price: 1200,
        priceId: "price_1Ol00HCGsJZY9lajjxXpsIX8",
        stripePlanId: "prod_PaAKOzOHtcTkVO",
        updatedAt: "2024-04-02T06:07:38.583Z",
        __v: 0,
        _id: "65fb72c095aaf7bae97fa044"
    },
    {
        active: true,
        createdAt: "2024-03-20T23:35:28.112Z",
        description: "Enterprise",
        isDeleted: false,
        metadata: {
            assistants_credit: "10",
            characters_credit: "5000000",
            conversations_credit: "500",
            segments_credit: "20",
        },
        name: "Enterprise",
        price: "",
        priceId: "price_1Ol00HCGsJZY9lajjxXpsIX8",
        stripePlanId: "prod_PaAKOzOHtcTkVO",
        updatedAt: "2024-04-02T06:07:38.583Z",
        __v: 0,
        _id: "65fb72c095aaf7bae97fa044"
    },
];

const hardcodedAddons = [
    {
        active: true,
        createdAt: "2024-03-20T23:35:27.534Z",
        description: "$7 per 100 conversations",
        isDeleted: false,
        metadata: { monthly: "false" },
        name: "Extra conversations (add-on)",
        price: 7,
        priceId: "price_1OldbOCGsJZY9lajvgjvqW4m",
        stripePlanId: "prod_PapFhTvCwUqJD4",
        updatedAt: "2024-04-02T06:07:38.147Z",
        __v: 0,
        _id: "65fb72bf95aaf7bae97fa03b"
    },
    {
        active: true,
        createdAt: "2024-03-20T23:35:27.730Z",
        description: "$10 per agent",
        isDeleted: false,
        metadata: { monthly: "true" },
        name: "Extra agents (add-on)",
        price: 10,
        priceId: "price_1OldcnCGsJZY9laj6TpgP2eB",
        stripePlanId: "prod_PapHu6k5LPPliA",
        updatedAt: "2024-04-02T06:07:37.931Z",
        __v: 0,
        _id: "65fb72bf95aaf7bae97fa03e"
    },
    {
        active: true,
        createdAt: "2024-03-20T23:35:27.921Z",
        description: "$39/month",
        isDeleted: false,
        metadata: { monthly: "true" },
        name: "Remove 'Powered By IntelSwift' (add-on)",
        price: 39,
        priceId: "price_1OldeSCGsJZY9lajRxOjhQi3",
        stripePlanId: "prod_PapJJmrJuFHyp7",
        updatedAt: "2024-04-02T06:07:37.699Z",
        __v: 0,
        _id: "65fb72bf95aaf7bae97fa041"
    },
];

export default function Pricing() {

    const [plans, setPlans] = useState(hardcodedPlans);
    const [addons, setAddons] = useState(hardcodedAddons)
    const [planType, setPlanType] = useState("Monthly");

    return (
        <>
            <Header />
            <Box className="pricing-container">
                <Box className="pricing-plans-container">
                    <Box className="pricing-title-container">
                        <Typography className="pricing-title" variant="h2"><span className="black-text">No hidden fees</span> <span className="gradient-text">Ever</span></Typography>
                        <Typography className="pricing-subtitle" variant="h4">Choose the plan that is right for you</Typography>
                        <Box className="pricing-title-btn-container">
                            <Tabs
                                className="plan-type-toggle"
                                value={planType}
                                onChange={(e, newValue) => { setPlanType(newValue) }}
                                role="navigation"
                            >
                                <Tab value="Monthly" label="Monthly" />
                                <Tab value="Annual" label="Annual -20%" />
                            </Tabs>
                        </Box>
                    </Box>
                    <section className="all-plans">
                        <Cards
                            planType={planType}
                            plans={plans}
                            source="pricing"
                        />
                    </section >
                        <Grid container spacing={2} className="inline-block-grid-item">
                            {addons.map((record, idx) => {
                                return (
                                    <Grid
                                        item
                                        key={`${record?.name}-${idx}`}
                                        xs={12}
                                        sm={12}
                                        md={6}
                                        lg={4}
                                        className="plans-pricing-card">
                                        <Addon record={record} source="pricing" />
                                    </Grid>
                                )
                            })}
                        </Grid>
                    <Box className="promotion-container">
                        <Typography className="promotion-container-title">Apply For A Special Startup Offer <span className="promotion-container-annually">$7 monthly</span> for business plan.</Typography>
                        <Typography className="promotion-container-description">Our mission is to help startups to do more for less, swiftly adapt to market dynamics, and better understand their customers.</Typography>
                        <Button href="https://forms.gle/zkNB67KKWgkYVQz4A" target="_blank" className="promotion-container-btn" size="large" color="primary" variant="contained">Apply</Button>
                    </Box>
                </Box >
                <Footer />
            </Box >
        </>
    )
}
