import { createTheme } from "@mui/material/styles";

export const DefaultTheme = createTheme({
  components: {
    MuiDataGrid: {
      styleOverrides: {
        root: {
          border: "none",
        },
      },
    },
    MuiButton: {
      variants: [
        {
          props: { variant: "contained" },
          style: {
            "border-radius": "8px",
            background: "#2196F3",
            "font-size": "12px",
            "font-style": "normal",
            boxShadow: 3,
          },
        },
        {
          props: { variant: "text" },
          style: {
            color: "#2196F3"
          },
        },
      ],
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
          boxShadow: "0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12);",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          textAlign: "left",
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        caption: {
          fontWeight: "300",
          fontSize: "1rem",
          lineHeight: "24px",
          letterSpacing: "0.15px",
          color: "rgba(0, 0, 0, 0.8)",
          fontFamily:
            "-apple-system, system-ui, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, Liberation Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji",
          "@media (max-width:600px)": {
            fontSize: "1rem",
          },
        },
        body1: {
          fontWeight: "400",
          fontSize: "1rem",
          color: "rgba(0, 0, 0, 0.8)",
          fontFamily:
            "-apple-system, system-ui, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, Liberation Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji",
          "@media (max-width:600px)": {
            fontSize: "1rem",
          },
        },
        stepText: {
          fontWeight: "300",
          fontSize: "1rem",
          color: "rgba(0, 0, 0, 0.8)",
          fontFamily:
            "-apple-system, system-ui, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, Liberation Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji",
          "@media (max-width:600px)": {
            fontSize: "0.5rem",
          },
        },
        h3: {
          fontWeight: "600",
          fontSize: "4.25rem",
          color: "rgba(77, 112, 237, 1)",
          lineHeight: "81.6px",
          fontFamily:
            "-apple-system, system-ui, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, Liberation Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji",
          "@media (max-width:600px)": {
            fontSize: "4.25rem",
          },
        },
        h4: {
          fontWeight: "400",
          fontSize: "2.5rem",
          color: "rgba(0, 0, 0, 0.8)",
          lineHeight: "48px",
          fontFamily:
            "-apple-system, system-ui, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, Liberation Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji",
          "@media (max-width:600px)": {
            fontSize: "1.5rem",
            lineHeight: "28.8px",
          },
        },
        h5: {
          fontWeight: "400",
          fontSize: "1.5rem",
          color: "rgba(0, 0, 0, 0.8)",
          lineHeight: "32.02px",
          fontFamily:
            "-apple-system, system-ui, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, Liberation Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji",
          "@media (max-width:600px)": {
            fontSize: "1.5rem",
            lineHeight: "28.8px",
          },
        },
        subtitle1: {
          fontWeight: "600",
          fontSize: "1rem",
          color: "rgba(0, 0, 0, 0.8)",
          lineHeight: "20px",
          fontFamily:
            "-apple-system, system-ui, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, Liberation Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji",
        },
      },
    },
  },
});
