// Import necessary components and hooks
import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AppBar, Toolbar, Box, Button, Typography, Stack, IconButton, Menu, MenuItem, Popover } from "@mui/material";
import { RxHamburgerMenu } from "react-icons/rx";
import { useTranslation } from "react-i18next";
import { IoIosArrowDown } from "react-icons/io";
// Import internal components and assets
import logo from "../../assets/img/horizontal-logo-for-white-background.png";
import { BOOK_DEMO_URL } from "../../utils/constants";
// Import CSS
import "./index.css";

const MAIN_APP_URL = process.env.REACT_APP_PRODUCTION_DOMAIN_ATOM_UI;
const Header = () => {
  const { t, i18n } = useTranslation();
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElLang, setAnchorElLang] = useState(null);
  const browserLanguage = navigator.language.toLowerCase();
  const defaultLanguage = browserLanguage.startsWith("en") || browserLanguage === "en" ? "en" : "uk";
  const [selectedLanguage, setSelectedLanguage] = useState(defaultLanguage);

  const navigate = useNavigate();
  const languages = [
    {
      id: "en",
      name: "English"
    },
    {
      id: "uk",
      name: "Українська"
    }
  ];

  useEffect(() => {
    const storedLanguage = localStorage.getItem("selectedLanguage");
    const languageToSet = storedLanguage || defaultLanguage;
    setSelectedLanguage(languageToSet);
    i18n.changeLanguage(languageToSet);
  }, []);

  // Event handler for language selection
  const handleLanguageSelection = (language) => {
    if (selectedLanguage !== language) {
      setSelectedLanguage(language);
      i18n.changeLanguage(language);
      localStorage.setItem("selectedLanguage", language);
      handleCloseLangPopover();
    }
  };

  // Event handlers for opening and closing the burger menu
  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  // Event handlers for opening and closing language menu
  const handleOpenLangPopover = (event) => {
    setAnchorElLang(event.currentTarget);
  };

  const handleCloseLangPopover = () => {
    setAnchorElLang(null);
  };

  // Function to navigate
  const handleNavigate = (path) => {
    handleCloseNavMenu(); // Close the menu upon navigation
    navigate(path);
  };
  // Render Header Component
  return (
    <AppBar className="header-wrapper">
      <Toolbar className="header-container">
        <img className="header-logo" src={logo} alt="Logo" />
        <Stack className="language-selection-stack" direction="row" spacing={1}>
          {/* Language selector */}
          <Button
            aria-label="language selection"
            aria-controls="lang-appbar"
            aria-haspopup="true"
            onClick={handleOpenLangPopover}
            sx={{ display: { xs: "flex", md: "none" } }}
          >
            <Typography variant="body2" className="language-selection"><IoIosArrowDown className="language-selection-icon" />{selectedLanguage === "en" ? "En" : "Uk"}</Typography>
          </Button>
          <Popover
            id="lang-appbar"
            open={Boolean(anchorElLang)}
            anchorEl={anchorElLang}
            onClose={handleCloseLangPopover}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <Stack spacing={1}>
              {languages.map((language) => (
                <MenuItem
                  key={language.id}
                  onClick={() => handleLanguageSelection(language.id)}
                  disabled={selectedLanguage === language.id}
                >
                  {language.name}
                </MenuItem>
              ))}
            </Stack>
          </Popover>
          <Box className="burger-menu">
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
            >
              <RxHamburgerMenu className="burger-menu-icon" />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
            >
              <MenuItem onClick={() => handleNavigate("/")}>
                <Typography textAlign="center">Solutions</Typography>
              </MenuItem>
              <MenuItem onClick={() => handleNavigate("/pricing")}>
                <Typography textAlign="center">Pricing</Typography>
              </MenuItem>
              <MenuItem onClick={() => handleNavigate("/")}>
                <Typography textAlign="center">About Us</Typography>
              </MenuItem>
              <MenuItem onClick={() => handleNavigate("/dashboards")}>
                <Typography textAlign="center">Login</Typography>
              </MenuItem>
              <MenuItem onClick={() => handleNavigate("/dashboards")}>
                <Typography textAlign="center">Try for free</Typography>
              </MenuItem>
            </Menu>
          </Box>
        </Stack>
        {/* Navigation Links for larger screens */}
        <Stack className="header-middle-section" spacing={4} direction="row">
          <Link to="/" className="header-links">
            <Typography variant="body2">{t("landingHeader.solutionsBtn")}</Typography>
          </Link>
          <Link to="/pricing" className="header-links">
            <Typography variant="body2">{t("landingHeader.pricingBtn")}</Typography>
          </Link>
          <Link to="/about" className="header-links">
            <Typography variant="body2">{t("landingHeader.aboutUsBtn")}</Typography>
          </Link>
          <Link to="/blog" className="header-links">
            <Typography variant="body2">{t("landingHeader.blogBtn")}</Typography>
          </Link>
        </Stack>
        {/* Action Buttons for larger screens */}
        <Stack className="header-right-section" spacing={2} direction="row">
          {/* Language selector */}
          <Button
            aria-label="language selection"
            aria-controls="lang-appbar"
            aria-haspopup="true"
            onClick={handleOpenLangPopover}
          >
            <Typography variant="body2" className="language-selection"><IoIosArrowDown className="language-selection-icon" />{selectedLanguage === "en" ? "En" : "Uk"}</Typography>
          </Button>
          <Popover
            id="lang-appbar"
            open={Boolean(anchorElLang)}
            anchorEl={anchorElLang}
            onClose={handleCloseLangPopover}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <Stack spacing={1}>
              {languages.map((language) => (
                <MenuItem
                  key={language.id}
                  onClick={() => handleLanguageSelection(language.id)}
                  disabled={selectedLanguage === language.id}
                >
                  {language.name}
                </MenuItem>
              ))}
            </Stack>
          </Popover>
          <Button className="demo-button" href={BOOK_DEMO_URL} target="_blank">Book a demo</Button>
          <Button className="login-button" onClick={() => window.open(MAIN_APP_URL, '_self', 'rel=noopener noreferrer')}>Login</Button>
          <Button className="try-for-free-button" variant="contained" onClick={() => window.open(MAIN_APP_URL, '_self', 'rel=noopener noreferrer')}>Try for free</Button>
        </Stack>
      </Toolbar>
    </AppBar>
  );
}

export default Header;
