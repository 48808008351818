import React, { useEffect } from 'react';
import { Route, Routes } from "react-router-dom";
import i18n from "./../i18n";
import LandingPage from "../pages/LandingPage";
import Privacy from "../pages/LandingPage/Privacy";
import Conditions from "../pages/LandingPage/Conditions";
import Pricing from "../pages/Pricing/index"
import BlogPage from './../pages/LandingPage/BlogsPage';
import AboutUs from './../pages/LandingPage/AboutUs';
import BlogOne from "./../components/Blogs/BlogOne.jsx";
import BlogTwo from './../components/Blogs/BlogTwo.jsx';
import BlogThree from './../components/Blogs/BlogThree.jsx';

const MainRoutes = () => {

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const langParam = urlParams.get("lang");
    if (langParam && i18n.language !== langParam) {
      i18n.changeLanguage(langParam);
      localStorage.setItem("selectedLanguage", langParam);
    } else {
      const storedLanguage = localStorage.getItem("selectedLanguage") || "en";
      i18n.changeLanguage(storedLanguage);
    }
  }, [i18n]);

  return (
    <Routes>
      <Route exect path="/" element={<LandingPage />} />
      <Route exect path="/about" element={<AboutUs />} />
      <Route exect path="/pricing" element={<Pricing />} />
      <Route exect path="/blog" element={<BlogPage />} />
      <Route exect path="/privacy-policy" element={<Privacy />} />
      <Route exect path="/terms-and-conditions" element={<Conditions />} />
      <Route exect path="/blog/1" element={<BlogOne />} />
      <Route exect path="/blog/2" element={<BlogTwo />} />
      <Route exect path="/blog/3" element={<BlogThree />} />
    </Routes>
  );
};

export default MainRoutes;