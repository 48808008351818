// --- Third Party Libraries ---
import React from "react";
import { Box, Typography, Card, CardMedia, CardContent, Button } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// --- Internal Components ---
import cardHeader1 from "./../../assets/img/picture-article_1.png";
import cardHeader2 from "./../../assets/img/picture-article_2.png";
import cardHeader3 from "./../../assets/img/picture-article_3.png";
// --- CSS ---
import "./index.css";

// --- Blog Component ---
const Blog = () => {
    // Step 1 - What I need to do on component load
    const imageMap = {
        cardHeader1: cardHeader1,
        cardHeader2: cardHeader2,
        cardHeader3: cardHeader3
    };
    const navigate = useNavigate();
    const { t } = useTranslation();
    // Render Blog Component
    return (
        <Box id="blog-section" className="blog-section">
            <Box className="blog-section-header">
                <Typography className="blog-section-title" variant="h2">
                    {t("landingHeader.blogSectionTitle")}
                </Typography>
                <Typography className="blog-section-subtitle" variant="body2">{t("landingHeader.blogSectionSubitle")}</Typography>
                <Button onClick={() => navigate('/blog')} className="read-blog-button" variant="contained">{t("landingHeader.readBlogButton")}</Button>
            </Box>
            <div className="blog-section-card-list">
                {Object.entries(t('blog.articles', { returnObjects: true })).map(([id, article]) => (
                    <Card key={id} className="blog-card"
                        onClick={() => navigate(`/blog/${id}`)}>
                        <CardMedia
                            className="blog-img"
                            component="img"
                            src={imageMap[`cardHeader${id}`]}
                            alt={article.title} />
                        <CardContent className="blog-content">
                            <Typography className="blog-title" variant="caption">
                                {article.title}
                            </Typography>
                            <Typography className="blog-subtitle" variant="body2">
                                {article.subtitle}
                            </Typography>
                            <Typography className="blog-date" variant="body2">
                                {article.date}
                            </Typography>
                        </CardContent>
                    </Card>
                ))}
            </div>
        </Box>
    );
}

export default Blog;