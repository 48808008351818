import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom'; 
import { DefaultTheme } from "./common_css/defaultTheme";
import { ThemeProvider } from '@mui/material/styles';
import Routes from './Routes';

function App() {
  return (
    <ThemeProvider theme={DefaultTheme}>
      <Router> 
        <Routes />
      </Router>
    </ThemeProvider>
  );
}

export default App;
