// --- Third Party Libraries ---
import React, { useState } from "react";
import { useTranslation } from 'react-i18next';
import { Box, Grid, Typography, Stack, Tabs, Tab, Card, CardHeader, CardContent, CardActions, Avatar } from "@mui/material";
import { IoMdSend } from "react-icons/io";
// --- Internal Components ---
import allInOneIcon1 from "./../../assets/img/all-in-one-icon1.png";
import allInOneIcon2 from "./../../assets/img/all-in-one-icon2.png";
import allInOneIcon3 from "./../../assets/img/all-in-one-icon3.png";
import icon1 from "../../assets/img/main-section-icon1.png";
import icon2 from "../../assets/img/main-section-icon2.png";
import icon3 from "../../assets/img/main-section-icon3.png";
import supportAvatar from "./../../assets/img/support-avatar.png";
import { generateQueryMsg, generateReplyMsg, RenderCardReply, renderButtons } from "../../components/chat/index";
// --- CSS ---
import "./index.css";

// --- ChatBots Component ---
const ChatBots = () => {
    // Step 1 - What I need to do on component load
    // State variables and hooks
    const [activeTab, setActiveTab] = useState(0);
    const { t } = useTranslation();

    // Step 2 - Event Handlers
    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue);

        // Scroll to the corresponding section within the card with smooth scrolling
        const sectionId = `tab${newValue + 1}`;
        const sectionElement = document.getElementById(sectionId);

        if (sectionElement) {
            sectionElement.scrollIntoView({
                behavior: 'smooth',
                block: 'nearest',
            });
        }
    };

    // Step 3 - Constants
    const tabLabels = [
        { title: t("landingHeader.firstChatbotsComponentTabTitle"), subtitle: t("landingHeader.firstChatbotsComponentTabSubtitle") },
        { title: t("landingHeader.secondChatbotsComponentTabTitle"), subtitle: t("landingHeader.secondChatbotsComponentTabSubtitle") },
        { title: t("landingHeader.thirdChatbotsComponentTabTitle"), subtitle: t("landingHeader.thirdChatbotsComponentTabSubtitle") },
        { title: t("landingHeader.fourthChatbotsComponentTabTitle"), subtitle: t("landingHeader.fourthChatbotsComponentTabSubtitle") },
    ];
    
    const engageContent = () => {
        return (
            <>
                {generateReplyMsg("Swift", icon3, t("landingHeader.engageContentReplyFirstMsg"))}
                <div className="chatbots-reply-buttons-container">
                    {renderButtons(t("landingHeader.engageContentReplyFirstBtn"))}
                    {renderButtons(t("landingHeader.engageContentReplySecondBtn"))}
                    {renderButtons(t("landingHeader.engageContentReplyThirdBtn"))}
                </div>
                {generateQueryMsg("Angelina", supportAvatar, t("landingHeader.engageContentQueryFirstMsg"))}
            </>
        )
    }

    const personilizeContent = () => {
        return (
            <>
                {generateReplyMsg("Swift", icon3, t("landingHeader.personilizeContentReplyFirstMsg"))}
                {generateQueryMsg("Angelina", supportAvatar, "Angelina")}
                {generateReplyMsg("Swift", icon3, t("landingHeader.personilizeContentReplySecondMsg"))}
                <div className="chatbots-card-row">
                    {RenderCardReply(icon1, t("landingHeader.personilizeContentReplyFirstIcon"))}
                    {RenderCardReply(icon2, t("landingHeader.personilizeContentReplySecondIcon"))}
                    {RenderCardReply(icon3, t("landingHeader.personilizeContentReplyThirdIcon"))}
                </div>
                {generateQueryMsg("Angelina", supportAvatar, t("landingHeader.personilizeContentQueryFirstMsg"))}
            </>
        )
    }

    const qualifyContent = () => {
        return (
            <>
                {generateReplyMsg("Swift", icon3, t("landingHeader.qualifyContentReplyFirstMsg"))}
                {generateQueryMsg("Angelina", supportAvatar, t("landingHeader.qualifyContentQueryFirstMsg"))}
                {generateReplyMsg("Swift", icon3, t("landingHeader.qualifyContentReplySecondMsg"))}
                {generateQueryMsg("Angelina", supportAvatar, t("landingHeader.qualifyContentQuerySecondMsg"))}
            </>
        )
    }

    const convertContent = () => {
        return (
            <>
                {generateReplyMsg("Swift", icon3, t("landingHeader.convertContentReplyFirstMsg"))}
                {generateQueryMsg("Angelina", supportAvatar, t("landingHeader.convertContentQueryFirstMsg"))}
                {generateReplyMsg("Swift", icon3, t("landingHeader.convertContentReplySecondMsg"))}
                <div className="chatbots-reply-buttons-container">
                    {renderButtons(t("landingHeader.convertContentReplyButtonsOne"))}
                    {renderButtons(t("landingHeader.convertContentReplyButtonsTwo"))}
                    {renderButtons(t("landingHeader.convertContentReplyButtonsThree"))}
                </div>
            </>
        )
    }

    // Render tabComponents Component
    const tabComponents = [
        <div className="chatbots-tabs-content">
            <Card className="chatbots-card">
                <CardHeader
                    className="chatbots-card-header"
                    avatar={
                        <Avatar className="chatbots-card-avatar">
                            <img className="chatbots-card-img" src={icon3} alt="Bot icon" />
                        </Avatar>
                    }
                    title={<Typography className="problem-card-actions-subtitle" variant="caption">Swift AI agent</Typography>}
                    subheader="Online"
                />
                <CardContent className="chatbots-card-scrollable-content">
                    <div id="tab1">
                        {engageContent()}
                    </div>
                    <div id="tab2">
                        {personilizeContent()}
                    </div>
                    <div id="tab3">
                        {qualifyContent()}
                    </div>
                    <div id="tab4">
                        {convertContent()}
                    </div>
                </CardContent>
                <CardActions className="chatbots-card-actions">
                    <Typography className="chatbots-card-actions-text" variant="caption">{t("landingHeader.chatbotsCardActionsText")}</Typography>
                    <IoMdSend className="chatbots-card-actions-icon" />
                </CardActions>
            </Card>
        </div>,
    ];

    const tabIndicatorStyle = {
        left: 0,
        backgroundColor: "rgba(141, 43, 255, 1)",
    };

    // Render ChatBots Component
    return (
        <>
            <Box className="chatbots-section">
                <Box className="all-in-one-component">
                    <Typography className="all-in-one-title" variant="h4">{t("landingHeader.allInOneTitle")}</Typography>
                    <Typography className="all-in-one-subtitle" variant="subtitle1">{t("landingHeader.allInOneSubtitlePartOne")} <br />{t("landingHeader.allInOneSubtitlePartTwo")}</Typography>
                    <Stack className="all-in-one-icons" spacing={2} direction="row">
                        <Stack className="square-container-with-text">
                            <div className="square-container">
                                <img className="chat-icon" src={allInOneIcon1} alt="Chat" />
                            </div>
                            <Typography className="product-title" variant="subtitle1">{t("landingHeader.firstSquareContainerWithText")}</Typography>
                        </Stack>
                        <Stack className="square-container-with-text">
                            <div className="square-container">
                                <img className="square-icon" src={allInOneIcon2} alt="Square" />
                            </div>
                            <Typography className="product-title" variant="subtitle1">{t("landingHeader.secondSquareContainerWithText")}</Typography>
                        </Stack>
                        <Stack className="square-container-with-text">
                            <div className="square-container">
                                <img className="stars-icon" src={allInOneIcon3} alt="Stars" />
                            </div>
                            <Typography className="product-title" variant="subtitle1">{t("landingHeader.thirdSquareContainerWithText")}</Typography>
                        </Stack>
                    </Stack>
                </Box>
                <Typography className="chatbots-component-title gradient-text" variant="h2">
                    <span className="black-text">{t("landingHeader.chatbotsComponentTitleFirstWord")}</span> {t("landingHeader.chatbotsComponentTitleSecondWord")}
                </Typography>
                <Grid container className="chatbots-component">
                    <Grid item md={6} lg={6}>
                        <Tabs
                            value={activeTab}
                            onChange={handleTabChange}
                            orientation="vertical"
                            variant="fullWidth"
                            className="chatbots-component-tabs-container"
                            TabIndicatorProps={{
                                style: tabIndicatorStyle,
                            }}
                        >
                            {tabLabels.map((tab, index) => (
                                <Tab className="chatbots-component-tab-container"
                                    key={index}
                                    label={
                                        <div className={`tab-label ${activeTab === index ? "active-tab" : ""}`}>
                                            <Typography className="chatbots-component-tabs-label-title" variant="h5">{tab.title}</Typography>
                                            <Typography className="chatbots-component-tabs-label-subtitle" variant="body2">{tab.subtitle}</Typography>
                                        </div>
                                    }
                                />
                            ))}
                        </Tabs>
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                        {tabComponents}
                    </Grid>
                </Grid>
            </Box>
        </>
    );
}

export default ChatBots;