import React, { useEffect } from 'react';
import { Box, Typography, Stack, Grid } from "@mui/material";
import { useTranslation } from 'react-i18next';
import { BarChart } from '@mui/x-charts/BarChart';
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import Header from './../../pages/LandingPage/Header';
import Footer from './../../pages/LandingPage/Footer';
import TableArticleTwo from "./../../assets/img/table-for-second-article.png"
import cardHeader2 from "../../assets/img/picture-article_2.png";

import "./index.css";

const BlogTwo = () => {
    const { t } = useTranslation();
    const series = [
        {
            name: 'Data',
            data: [20, 70],
            color: ['#2A52BE', '#007BA7']
        }
    ];
    const xAxisConfig = [{
        scaleType: 'band',
        data: ['2023', '2024']
    }];
    const yAxisConfig = [
        {
            valueFormatter: (value) => `${value}%`
        }
    ]
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <Header />
            <Box className="breadcrumbs-container">
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" href="/blog" className="breadcrumbs-link">
                        {t("blogTwo.breadcrumbsLinkBlog")}
                    </Link>
                    <Typography color="text.primary">{t("blogTwo.blogOneTitle")}</Typography>
                </Breadcrumbs>
            </Box>
            <Box className="blog-post-container">
                <Box className="blog-post-title-container">
                    <Grid container spacing={2}>
                        <Grid item md={6} xs={12}>
                            <Typography className="blog-post-title" variant="h1">
                                {t("blogTwo.blogOneTitle")}
                            </Typography>
                            <Typography className="blog-post-subtitle" variant="h2">
                                {t("blogTwo.blogOneSubtitle")}
                            </Typography>
                            <Stack spacing={1} direction="column" className="blog-copy-right">
                                <Typography className="blog-post-subtitle" variant="subtitle2">
                                    {t("blogTwo.blogPostBy")}
                                </Typography>
                                <Typography className="blog-post-subtitle" variant="subtitle2">
                                    {t("blogTwo.blogPostDate")}
                                </Typography>
                            </Stack>
                        </Grid>
                        <Grid item md={6} xs={12} >
                            <section className='blog-image'>
                                <img src={cardHeader2} />
                            </section>

                        </Grid>
                    </Grid>
                </Box>
                <Box className="blog-content">
                    <Box>
                        <Typography variant="body1" className="blog-post-description">
                            {t("blogTwo.firsParagraphtLineOne")}
                            <a href="https://go.emplifi.io/rs/284-ENW-442/images/Emplifi_Report_Consumer_Expectations_US_UK_EN.pdf"> {t("blogTwo.firsParagraphtLink")}</a> {t("blogTwo.firsParagraphtLineTwo")}</Typography>
                    </Box>
                    <Box className="article-two-table">
                        <img className="article-two-table-img" src={TableArticleTwo} />
                        <Typography className="article-two-table-text" variant="body1">{t("blogTwo.tableSource")}</Typography>
                    </Box>
                    <Box>
                        <Typography variant="body1" className="blog-post-description">
                            {t("blogTwo.secondParagraphtLineOne")}<a href="https://downloads.ctfassets.net/xny2w179f4ki/3FxNFG5dIUBgphM6xqLgPy/ecfaca62ff0550e4d345b31addbff762/Intercom_Customer_Service_Trends_Report_2024.pdf"> {t("blogTwo.secondParagraphtLink")}</a> {t("blogTwo.secondParagraphtLineTwo")}</Typography>
                    </Box>
                    <Box className="charts-container">
                        <BarChart
                            xAxis={xAxisConfig}
                            yAxis={yAxisConfig}
                            series={series}
                            width={390}
                            height={300}
                            axisHighlight={{ x: 'none', y: 'none' }}
                        />
                        <Typography className="article-two-table-text" variant="body1">{t("blogTwo.chartSource")}</Typography>
                    </Box>
                    <Box>
                        <Typography variant="body1" className="blog-post-description">
                            {t("blogTwo.thirdParagraphtLineOne")}</Typography>
                    </Box>
                    <Box className="blog-two-li-container">
                        <Typography variant="h3" className="title-paragraph">{t("blogTwo.casesOnwAiShinesTitle")}</Typography>
                        <Typography variant="body1" className="blog-post-description">{t("blogTwo.casesOneAiShinesParagraphOne")}
                            <br /><br />{t("blogTwo.casesOneAiShinesParagraphTwo")}</Typography>
                    </Box>
                    <Box className="blog-two-li-container">
                        <Typography variant="h3" className="title-paragraph">{t("blogTwo.casesTwoAiShinesTitle")}</Typography>
                        <Typography variant="body1" className="blog-post-description">{t("blogTwo.casesTwoAiShinesParagraphOne")}</Typography>
                    </Box>
                    <Box className="blog-two-li-container">
                        <Typography variant="h3" className="title-paragraph">{t("blogTwo.casesThreeAiShinesTitle")}</Typography>
                        <Typography variant="body1" className="blog-post-description">{t("blogTwo.casesThreeAiShinesParagraphOne")}</Typography>
                    </Box>
                    <Box className="blog-two-li-container">
                        <Typography variant="h3" className="title-paragraph">{t("blogTwo.casesFourAiShinesTitle")}</Typography>
                        <Typography variant="body1" className="blog-post-description">{t("blogTwo.casesFourAiShinesParagraphOne")}
                        </Typography>
                    </Box>
                    <Box className="blog-two-li-container">
                        <Typography variant="h3" className="title-paragraph">{t("blogTwo.casesFiveAiShinesTitle")}</Typography>
                        <Typography variant="body1" className="blog-post-description">{t("blogTwo.casesFiveAiShinesParagraphOne")}
                        </Typography>
                    </Box>
                    <Box className="blog-two-li-container">
                        <Typography variant="h3" className="title-paragraph">{t("blogTwo.casesSixAiShinesTitle")}</Typography>
                        <Typography variant="body1" className="blog-post-description">{t("blogTwo.casesSixAiShinesParagraphOne")}
                        </Typography>
                    </Box>
                    <Box className="blog-two-li-container">
                        <Typography variant="h3" className="title-paragraph">{t("blogTwo.casesSevenAiShinesTitle")}</Typography>
                        <Typography variant="body1" className="blog-post-description">{t("blogTwo.casesSevenAiShinesParagraphOne")}
                        </Typography>
                    </Box>
                    <Box className="blog-two-last-li-container">
                        <Typography variant="h3" className="title-paragraph">{t("blogTwo.casesEightAiShinesTitle")}</Typography>
                        <Typography variant="body1" className="blog-post-description">{t("blogTwo.casesEightAiShinesParagraphOne")}
                        </Typography>
                    </Box>
                    <Box>
                        <Typography className="blog-post-subtitle" variant="h6">{t("blogTwo.fourthParagraphtTitle")}</Typography>
                        <Typography variant="body1" className="blog-post-description">
                            {t("blogTwo.fourthParagraphtLineOne")} <a href="https://cxtrends.zendesk.com"> {t("blogTwo.fourthParagraphtLink")}</a> {t("blogTwo.fourthParagraphtLineTwo")}</Typography>
                    </Box>
                    <Box className="blog-post-container-background">
                        <ul>
                            <li><strong>{t("blogTwo.containerBackgroundlistOneStrong")}</strong> {t("blogTwo.containerBackgroundlistOne")}</li>
                            <li><strong>{t("blogTwo.containerBackgroundlistTwoStrong")}</strong> {t("blogTwo.containerBackgroundlistTwo")}</li>
                            <li><strong>{t("blogTwo.containerBackgroundlistThreeStrong")}</strong> {t("blogTwo.containerBackgroundlistThree")}</li>

                        </ul>
                    </Box>
                    <Box>
                        <Typography variant="body1" className="blog-post-description">
                            {t("blogTwo.fifthParagraphtLineOne")}<a href="https://intelswift.com"> {t("blogTwo.fifthParagraphtLink")}</a> {t("blogTwo.fifthParagraphtLineTwo")}
                            <br /><br />
                            <a href="https://intelswift.youcanbook.me">Book a demo</a> <strong>{t("blogTwo.lastStrongParagraf")}</strong> </Typography>
                    </Box>
                </Box>
            </Box >
            <Footer />
        </>
    )
}
export default BlogTwo