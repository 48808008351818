import React, { useEffect } from 'react';
import { Box, Typography, Stack, Grid } from "@mui/material";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { useTranslation } from 'react-i18next';
import Link from "@mui/material/Link";
import Header from '../../pages/LandingPage/Header';
import Footer from '../../pages/LandingPage/Footer';
import FirstImg from "../../assets/img/first_img_third_blog.png"
import SecondImg from "./../../assets/img/second_img_third_blog.png"
import ThirdImg from "./../../assets/img/third__img_third_blog.png"
import cardHeader3 from "../../assets/img/picture-article_3.png";

import "./index.css";

const BlogThree = () => {

    const { t } = useTranslation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <Header />
            <Box className="breadcrumbs-container">
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" href="/blog" className="breadcrumbs-link">
                        {t("blogThree.breadcrumbsLinkBlog")}
                    </Link>
                    <Typography color="text.primary">{t("blogThree.blogOneTitle")}</Typography>
                </Breadcrumbs>
            </Box>
            <Box className="blog-post-container">
                <Box className="blog-post-title-container">
                    <Grid container spacing={2}>
                        <Grid item md={6} xs={12}>
                            <Typography className="blog-post-title" variant="h1">
                                {t("blogThree.blogOneTitle")}
                            </Typography>
                            <Typography className="blog-post-subtitle" variant="h2">
                                {t("blogThree.blogOneSubtitle")}.
                            </Typography>
                            <Stack spacing={1} direction="column" className="blog-copy-right">
                                <Typography className="blog-post-subtitle" variant="subtitle2">
                                    {t("blogThree.blogPostBy")}
                                </Typography>
                                <Typography className="blog-post-subtitle" variant="subtitle2">
                                    {t("blogThree.blogPostDate")}
                                </Typography>
                            </Stack>
                        </Grid>
                        <Grid item md={6} xs={12} >
                            <section className='blog-image'>
                                <img src={cardHeader3} />
                            </section>

                        </Grid>
                    </Grid>
                </Box>
                <Box className="blog-content">
                    <Box className="blog-two-li-container">
                        <Typography variant="body1" className="blog-post-description"> {t("blogThree.firsParagraphtLineOne")}<br /><br />
                            {t("blogThree.firsParagraphtLineTwo")} </Typography>
                    </Box>
                    <Box className="blog-two-li-container">
                        <Typography className="title-paragraph" variant="h3">{t("blogThree.firsParagraphtTitle")}  </Typography>
                        <Typography variant="body1" className="blog-post-description"><strong>{t("blogThree.secondParagraphtLineOneStrong")}</strong>{t("blogThree.secondParagraphtLineOne")}
                            <br /><br />
                            {t("blogThree.secondParagraphtLineTwo")}
                            <br /><br />{t("blogThree.secondParagraphtLineThree")} </Typography>
                    </Box>
                    <Box className="blog-two-li-container">
                        <Typography variant="h3" className="title-paragraph">{t("blogThree.thirdParagraphtTitle")} </Typography>
                        <Typography variant="body1" className="blog-post-description">{t("blogThree.thirdParagraphtLineOne")} </Typography>
                    </Box>
                    <Box className="img-container">
                        <img src={FirstImg} className="article-three-img-one" />
                        <img src={SecondImg} className="article-three-img-two" />
                    </Box>
                    <Box className="blog-two-li-container">
                        <Typography variant="body1" className="blog-post-description">{t("blogThree.thirdParagraphtLineTwo")} <br /><br />{t("blogThree.thirdParagraphtLineThree")} </Typography>
                    </Box>
                    <Box className="blog-two-li-container">
                        <Typography variant="h3" className="title-paragraph">{t("blogThree.fourthParagraphtTitle")}</Typography>
                        <Typography variant="body1" className="blog-post-description">{t("blogThree.fourthParagraphtLineOne")}
                            <br /><br /> {t("blogThree.fourthParagraphtLineTwo")}
                            <br /><br /> {t("blogThree.fourthParagraphtLineThree")}</Typography>
                    </Box>
                    <Box className="blog-two-li-container">
                        <Typography variant="h3" className="title-paragraph">{t("blogThree.fifthParagraphtTitle")}</Typography>
                        <Typography variant="body1" className="blog-post-description">{t("blogThree.fifthParagraphtLineOne")}</Typography>
                    </Box>
                    <Box className="img-container">
                        <img src={ThirdImg} className="article-three-img-three" />
                    </Box>
                    <Box className="blog-two-li-container">
                        <Typography variant="body1" className="blog-post-description">{t("blogThree.fifthParagraphtLineTwo")}</Typography>
                    </Box>
                    <Box className="blog-two-li-container">
                        <Typography variant="h3" className="title-paragraph">{t("blogThree.sixthParagraphtTitle")}</Typography>
                        <Typography variant="body1" className="blog-post-description">{t("blogThree.sixthParagraphtLineOne")}
                            <br /><br /> {t("blogThree.sixthParagraphtLineTwo")} <a href="https://intelswift.com">{t("blogThree.sixthParagraphtLink")} </a>{t("blogThree.sixthParagraphtLineThree")}<br /><br />{t("blogThree.sixthParagraphtLineFour")}</Typography>
                    </Box>
                </Box>
            </Box>
            <Footer />

        </>
    )
}
export default BlogThree;
